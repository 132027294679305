.contact-sec-1{
    padding-top:60px;
    padding-left:100px;
    padding-right:100px;
}
.get-in-touch-h3{
    font-family:'Muli';
    font-size:36px;
    font-weight: 500;
    letter-spacing: 0.9px;
    line-height: 85px;
    text-align: left;
    color:#1E1E1E;
    margin-top:10px;
}
.tell-us-p{
    font-family: 'Open Sans';
    font-size:16px;
    font-weight: 600;
    letter-spacing: 0.9px;
    line-height: 22px;
    text-align: left;
    color: #1E1E1E;
}
.name-input, .phone-input, .email-input, .message-input{
    font-family: 'Muli';
    font-size:16px;
    font-weight: 600;
    letter-spacing: 0.9px;
    line-height: 20px;
    text-align: left;
    color: #1E1E1E;
    border-radius: 2px;
    max-width: 445px;
}
.submit-btn{
    font-family:'Open Sans';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.9px;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
    background-color:#F9673E;
    border: none;
    padding:11px 25px;
    margin-top: 10px;
}
.submit-btn:hover{
    cursor: pointer;
}
.reach-us-h3{
    font-family: 'Muli';
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0.9px;
    line-height: 85px;
    text-align: left;
    color: #1E1E1E;
}
.phone-p, .email-p, .address-head-p{
    font-family: 'Muli';
    font-size:18px;
    font-weight: 700;
    letter-spacing: 0.9px;
    line-height: 19px;
    text-align: left;
    color: #F9673E;
}
.phone-number-p,.email-id-p{
    font-family: 'Muli';
    font-size:18px;
    font-weight: 700;
    letter-spacing: 0.9px;
    line-height: 0px;
    text-align: left;
    color: #1E1E1E;
}
.phone-number-p:hover{
    font-family: 'Muli';
    font-size:18px;
    font-weight: 700;
    letter-spacing: 0.9px;
    line-height: 0px;
    text-align: left;
    color: #1E1E1E;
    text-decoration: none;
}
.email-id-p{
    width:258px;
}
.email-id-p a{
    word-break: break-all;
    line-height: 20px;
}
.email-id-p:hover{
    font-family: 'Muli';
    font-size:18px;
    font-weight: 700;
    letter-spacing: 0.9px;
    line-height: 20px;
    text-align: left;
    color: #1E1E1E;
    text-decoration: none;
}
.address-p{
    font-family: 'Muli';
    font-size:18px;
    font-weight: 700;
    letter-spacing: 0.9px;
    line-height: 20px;
    text-align: left;
    color: #1E1E1E;
    margin-top: -10px;
}
.address-p:hover{
    font-family: 'Muli';
    font-size:18px;
    font-weight: 700;
    letter-spacing: 0.9px;
    line-height: 20px;
    text-align: left;
    color: #1E1E1E;
    margin-top: -10px;
    text-decoration: none;
}
.reach-us-div{
    background-color: #F6F6F6;
    padding-left:60px;
    padding-top:40px;
    padding-bottom:50px;
}
.email-img-p, .locations-img-p{
    margin-top:40px;
}
.get-direction-btn, .get-direction-btn:hover{
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.9px;
    line-height: 22px;
    text-align: center;
    color: #172A1D;
    padding:11px 25px;
    border:2px solid #707070;
    text-decoration: none;
}
.get-direction-btn-p{
    margin-top:50px;
}
.get-exclusive-h3{
    font-family: 'Muli';
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.9px;
    line-height: 85px;
    text-align: left;
    color: #1E1E1E;
}
.email-subscribe-input{
    font-family: 'Roboto';
    font-size:16px;
    letter-spacing: 0.9px;
    line-height: 21px;
    text-align: left;
    color: #172A1D;
    height:46px;
    padding:0px 25px;
}
.get-exclusive-row{
    background-color: #FFCF27;
    padding-top:10px;
    padding-bottom:0px;
    padding-left:30px;
    margin-top:60px;
    margin-bottom:60px;
}
.subscribe-btn{
    font-family: 'Open Sans';
    font-size:16px;
    font-weight: 600;
    letter-spacing: 0.9px;
    line-height: 22px;
    text-align: left;
    color: #FFFFFF;
    background-color: #182C3A;
    border: none;
    padding:8px 25px;
    margin-left:-4px;
}
.subscribe-div{
    margin-top:20px;
}
.reach-us-col5{
    padding-right:0px;
}
.subscribe-form-div{
    width:90%;
    margin-left:30px;
}
.name-group-div{
    margin-bottom:30px;
}
.footer-link-a, .footer-link-a:hover{
    font-size: 16px;
    color: #182C3A;
    letter-spacing: .7px;
    margin-bottom: 12px;
    text-decoration: none;
}
.error{
    color:red;
}
#contact_subscribe-error, #footer_subscribe-error{
    position: absolute;
    bottom: -30px;
}