.coming-soon-sec1{
    background-image: url("../images/banner-index.jpg");
    background-repeat: no-repeat;
    // background-position: center;
    background-size: cover;
    padding:48px 0;
    height: 105vh;
}
// .comingSoon-logo-div{
//     margin-top: 58px;
// }
// .biryani-div1{
//     margin-top: 69px;
// }
.biryani-img1{
    width: 268px;
}
.hyderabadi-h3{
    font-family: 'Merienda One';
    font-size: 37px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 25px;
    text-align: left;
    color: #B9B896;
    margin-top: 28px;
}
.best-restaurant-p{
    font-family: 'Muli';
    font-size:32px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 32px;
    text-align: left;
    color: #CBCBCB;
    margin-top: 15px;
}
.authentic-p{
    font-family: 'Muli';
    font-size:20px;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 5px;
    text-align: left;
    color: #CBCBCB;   
}
.select-loc-p{
    font-family: 'Muli';
    font-size:16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 12px;
    text-align: left;
    color: #FFFFFF;
    margin-left:0;
}
.location-add-div1{
    background-color: #ffffff;
    border-radius: 0;
    border: none;
    height: 105px;
}
.location-add-div2{
    background-color: #F9673E;
    border-radius: 0;
    height:116px;
    border: none;
}
// .location-addHead-div1{
//     max-width: 309px;
//     margin: 0 75px 0 auto;
// }
// .location-addHead-div2{
//     max-width: 309px;
//     margin: 0 auto 0 75px;
// }
.loc-name-p{
    font-family: 'Merienda One', cursive;
    font-size:28px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 24px;
    text-align: center;
    color: #ADAB5F;
    margin-top:20px;
}
.now-take-order-p{
    font-family: 'Muli';
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center;
    color:#1E1E1E;
}
.launch{
    font-size: 14px !important;
    line-height: 20px !important;
}
.halal-logo{
    position: absolute;
    padding-top: 20px;
    padding-left: 20px;
    z-index: 10021;
  }
  .halal-logo-mobile{
    position: absolute;
    padding-top: 10px;
    padding-left: 10px;
    z-index: 100;
  }
.loc-address-p{
    font-family: 'Muli';
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
}
.loc-address-p img{
    margin-top:-8px;
}
.loc-address-p2{
    font-family: 'Muli';
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.5px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    margin: auto;
    margin-top: 5px;
    max-width: 290px;
}
.follow-p-us{
    font-family: 'Muli';
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 15px;
    text-align: left;
    color: #ffffff;
}
// .follow-div-on{
//     // margin-left:45px;
//     // margin-bottom:40px;
//     position: absolute;
//     bottom:0;
// }
.followUs-row-desk{
    margin-top:10px;
}
.location-card-a:hover{
    text-decoration: none;
}
.index-logo{
    width:130px;
    height:87px;
}
.followUs-container-mobi{
    display: none;
}
// .select-loccard-row-desk{
//     display: block;
// }
.select-loccard-row-mobi{
    display:none;
}


#our-location-index .owl-item .locationcard-img{
    display: inline;
    width: auto;
}
#our-location-index .owl-item .item{
    max-width: 90%;
    margin: 0 auto;
}
#our-location-index .owl-prev{
    position: absolute;
    top: 37%;
    left: -34px;
    @media(max-width:768px){
        top: 33%;
        left: -15px;
    }
}
#our-location-index .owl-next{
    position: absolute;
    top: 37%;
    right: -34px;
    @media(max-width:768px){
        top: 33%;
        right: -15px;
    }
}
.desktop-view{
    display: block;
    @media(max-width:768px){
        display: none;
    }
}
.mobile-view{
    display: none !important;
    @media(max-width:768px){
        display: block !important;
    }
}


