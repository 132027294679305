.bm-home-sec1{
    background-image: url(../images/slider-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
}
.bm-home-sec2{
    background-color: #ffffff;
    padding: 90px 30px;
}
.bm-home-sec3{
    background-color: #1E1E1E;
    padding: 90px 30px;
}
.bm-home-sec4{
    background-color: #ffffff;
    padding: 90px 30px;
}
.bm-home-sec5{
    background-color: #F6F6F6;
}
.bm-home-sec2-left {
    overflow: hidden;
    min-height: 50px;
}
.bm-home-sec2-right {
    float: right;
    width: 400px;
    min-height: 50px;
    margin-left: 10px;
}
.scroll-sec-div{
  // margin: 20px;
  border: 4px solid #7A7A7A;
}
.view-menu-svg{
  max-width: 15px;
}
.bw-home-slide-div{
  position: relative;
}
.bw-home-slide-divinner{
  position: absolute;
    bottom: 20px;
    background-color: #ffffff;
    width: 320px;
    padding-left: 20px;
}

.bw-home-slide1-p1{
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  letter-spacing: .7px;
}
.bw-home-slide1-p2{
  font-size: 24px;
  font-weight: 300;
  letter-spacing: .7px;
  margin-bottom: 5px;
  a{
    color: #182C3A;
  display: inline-flex;
  }
}
.bm-home-sec2-timings{
    background-color: #1E1E1E;
    width: 372px;
    border: 4px solid #727272;
    outline: 10px solid #1E1E1E;
    padding: 8px 8px;
    text-align: center;
}
.bw-fe-row2{
  margin-top: 80px;
}
.bw-sec2-fe-row{
    background-color: #EFEBDD;
    padding: 30px 60px;
}
.bw-sec2-fe-col-p{
    color: #182C3A;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 0;
    margin-top: 12px;
}
.bm-home-sec2-left-h3{
    font-size: 24px;
    font-weight: 400;
    color: #182C3A;
}
.bm-home-sec2-left-p1{
    font-size: 54px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 35px;
}
.bm-home-sec2-left-p2{
    max-width: 560px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 32px;
    margin-bottom: 55px;
    color: #172A1D;
}
.bm-home-sec2-left-p3 a{
    color: #0055FF;
    font-size: 16px;
    font-weight: 400;
    margin-left: 20px;
}
.bm-home-sec2-left-p3 a{
    text-decoration: none;
}
// 


/* Icon Wobble Horizontal */
@-webkit-keyframes hvr-icon-wobble-horizontal {
    16.65% {
      -webkit-transform: translateX(6px);
      transform: translateX(6px);
    }
    33.3% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }
    49.95% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
    66.6% {
      -webkit-transform: translateX(-2px);
      transform: translateX(-2px);
    }
    83.25% {
      -webkit-transform: translateX(1px);
      transform: translateX(1px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes hvr-icon-wobble-horizontal {
    16.65% {
      -webkit-transform: translateX(6px);
      transform: translateX(6px);
    }
    33.3% {
      -webkit-transform: translateX(-5px);
      transform: translateX(-5px);
    }
    49.95% {
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
    66.6% {
      -webkit-transform: translateX(-2px);
      transform: translateX(-2px);
    }
    83.25% {
      -webkit-transform: translateX(1px);
      transform: translateX(1px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  .hvr-icon-wobble-horizontal {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-icon-wobble-horizontal .hvr-icon {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .hvr-icon-wobble-horizontal:hover .hvr-icon, .hvr-icon-wobble-horizontal:focus .hvr-icon, .hvr-icon-wobble-horizontal:active .hvr-icon {
    -webkit-animation-name: hvr-icon-wobble-horizontal;
    animation-name: hvr-icon-wobble-horizontal;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }



/* Icon Drop */
@-webkit-keyframes hvr-icon-drop {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    51%,
      100% {
      opacity: 1;
    }
  }
  @keyframes hvr-icon-drop {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    51%,
      100% {
      opacity: 1;
    }
  }
  /* Icon Drop */
  .hvr-icon-drop {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }
  .hvr-icon-drop .hvr-icon {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .hvr-icon-drop:hover .hvr-icon, .hvr-icon-drop:focus .hvr-icon, .hvr-icon-drop:active .hvr-icon {
    opacity: 0;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-animation-name: hvr-icon-drop;
    animation-name: hvr-icon-drop;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    animation-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }

  .bm-timings-h3{
      color: #D9D2B9;
      font-size: 18px;
      letter-spacing: .8px;
      margin-bottom: 5px;
  }
  .bm-timings-p{
      font-weight: 800;
      font-size: 43px;
      letter-spacing: .7px;
      color: #FFFFFF;
      margin-bottom: 12px;
  }
  .bm-timings-p1{
      color: #FFFFFF;
      font-size: 18px;
      letter-spacing: 1px;
  }
  .bm-timings-divs{
    width: 100%;
    height: 1px;
    margin: 35px 0;
    background-color: #707070;
  }
  .bm-home-sec3-h2{
    color: #C9C0A6;
    font-size: 24px;
    font-weight: 400;
  }
  .bm-home-sec3-p{
    font-size: 54px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 35px;
    color: #ffffff;
  }
  .bm-home-sec4-p1{
      color: #182C3A;
      font-weight: 800;
      font-size: 54px;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 10px;
  }
  .bm-home-sec4-h4{
      text-align: center;
      font-size: 20px;
      letter-spacing: .7px;
      margin-bottom: 80px;
  }
  .bm-home-sec4-h4-a,.bm-home-sec4-h4-a:hover{
    text-align: center;
    font-size: 20px;
    letter-spacing: .7px;
    margin-bottom: 80px;
    color: #182C3A;
    text-decoration: none;
  }


  /* Icon Grow */
.hvr-icon-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-icon-grow .hvr-icon {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-icon-grow:hover .hvr-icon, .hvr-icon-grow:focus .hvr-icon, .hvr-icon-grow:active .hvr-icon {
    -webkit-transform: scale(1.1) translateZ(0);
    transform: scale(1.1) translateZ(0);
  }

  // biryani slider
  .bw-home-slide-div{
    padding: 20px;
  }
  button:focus{
    outline: none;
  }
  .every-tuesday-p{
    font-family: 'Merienda One', cursive;
    font-size: 16px;
    color: #fff;
  }
  .bm-home-sec2-timings-div2{
    // border: 4px solid #ccc;
    padding:20px 25px;
  }
  .instagram_gallery{
    display:block;
  }
  #instagram-feed1 .item img {
    max-width: 100%;
    height: 259px;
    margin-right:2px;
}
.insta-col-div{
  padding-right:0px;
}
.instagram_feed{
  margin-left:18px;
}

.insta-cam-img{
  max-width: 260px;
}
.insta-col-mobi{
  margin: auto;
}
.order-online-a{
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 25px;
  padding-left: 25px;
  color: #ffffff;
  background: #F9673E;
}
.order-online-a:hover{
  text-decoration: none;
  color: #ffffff;
}
.order-online-mobi{
  display: none;
  background-color: #F9673E;
  position: fixed;
  bottom:0;
  width: 100%;
  text-align: center;
  padding: 18px 0;
  z-index: 999;
}
.order-online-mobi-a{
  background-color: #ffffff;
  padding: 10px 55px;
  color: #000000;
  font-weight: 700;
}
.loc-address-p2-comingsoon{
    color: black;
    font-size: 25px;
    font-weight: 800;
}
.location-add-div2-comingsoon{
  background-color: #fff;
  text-align: center;
  border-radius: 0;
  height: 116px;
  border: none;
}
.book-now-submit-catering{
  background-color: #f9673e;
  color: #fff;
}
.food-note{
  border-top: 1px solid #000000;
  padding-top: 3px;
}
