.bm-menu-sec1-p1{
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}
.bm-menu-sec1{
    padding: 35px;
}
.menu-accordion{
    // .card{
    //     border-top:0;
    // }
    .menu-biryani-img{
        max-width: 60px;

    }
    .card-title{
        font-size: 21px;
        font-weight: 800;
        text-transform: uppercase;
    }
    .accordion .card-header{
        background-color: #ffff;
        border: 0;
        box-shadow: 0 4px 6px #00000016;
        margin: 15px 0;
    }
    .accordion .card-header:hover{
        cursor: pointer;
    }
    .accordion .card-header:after {
        font-family: 'FontAwesome';  
        content: "\f068";
        float: right; 
        border: 1px solid #22252a;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        padding: 2px 8px;
    }
    .accordion .card-header.collapsed:after {
        /* symbol for "collapsed" panels */
        content: "\f067";
    }
    .menu-accordion-th1{
        width: 50%;
    }
    .menu-accordion-th span{
        font-size: 16px;
        color: #182C3A;
    }
    .menu-accordion-tbl td{
        font-size: 21px;
        font-weight: 700;
        letter-spacing: .7px;
    }
}
.menu-accordion{
    margin-top:40px;
}
.header-row-border{
    border-bottom: 1px solid #ccc;
}
.food-allergy-fixed{
    display:none;
}
@media(max-width:768px){
    .food-allergy-fixed{
        display:block;
        position: fixed;
        bottom: 50px;
        background-color: #fff;
        opacity: 0.85;
    }
    .footer-top-border {
        border-top: 1px solid #c7c7c7;
        padding: 15px 0;
        text-align: center;
    }
    .footer-top-border p {
        color: #959596;
        margin: 0;
        font-size: 14px;
        font-weight: 500;
    }
}