.svc-main-slider{

.slide-container {
    width: 100%;
    height: 100%;
    float: left;
    background-color: #000000;
    overflow: hidden;
    position: relative;
}

.slide-holder {
    width: 100%;
    height: 100%;
    float: left;
    position: relative;
    background: #0e2345;
}

.half-circle-next, .half-circle-prev {
    width: 70px;
    height: 70px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    margin-top: -30px;
    padding-top: 2px;
    background: rgba(0, 0, 0, 0.5);
    border-width: 0px;
    color: #fff;
    font-style: normal;
    font-size: 20px;
    position: absolute;
    top: calc(50% - 15px);
    z-index: 10;
    cursor: pointer;
}

.half-circle-prev {
    left: -30px;
    margin-right: -23px;
    float: left;
    z-index: 100;
}

.half-circle-next {
    right: -30px;
    margin-left: -23px;
    float: right;
    z-index: 100;
}

.half-circle-prev span {
    margin-right: -23px
}

.half-circle-next span {
    margin-left: -23px
}

.fade {
    opacity: 1.0;
    display: bloc;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.fade img {
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.card {
    opacity: 1.0;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all 0.6s;
    overflow: hidden;
}

.card img {
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.box3D {
    opacity: 1.0;
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.box3D img {
    max-width: none;
    width: 100%;
    height: 100%;
    position: relative;
}

.glide {
    opacity: 1.0;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: all 0.6s;
    overflow: hidden;
}

.glide img {
    max-width: none;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // opacity: .5;
    opacity: 111;
}

.slice {
    opacity: 1.0;
    display: block;
    height: 100%;
    float: left;
    position: relative;
    transition: all .8s cubic-bezier(.175, .885, .32, 1.275);
    overflow: hidden;
}

.slice img {
    max-width: none;
    width: 100%;
    height: 100%;
    float: left;
    object-fit: cover;
}

.flip {
    opacity: 1.0;
    display: block;
    height: 100%;
    float: left;
    position: relative;
    overflow: hidden;
}

.flipper {
    transition: all 0.6s;
    transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    transform-style: preserve-3d;
}

.flip img {
    max-width: none;
    width: 100%;
    height: 100%;
    float: left;
    object-fit: cover;
}

.pixel {
    opacity: 1.0;
    display: block;
    height: 100%;
    float: left;
    position: relative;
    overflow: hidden;
}

.pixel img {
    max-width: none;
    width: 100%;
    height: auto;
    float: left;
    object-fit: cover;
}

.pixel-rotate {
    transition: all 0.6s;
    transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    transform-style: preserve-3d;
}

.pixel-scale {
    -webkit-animation-duration: .6s;
    -o-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-timing-function: ease-out;
    -o-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    -webkit-animation-name: scale;
    -o-animation-name: scale;
    animation-name: scale;
}

@-webkit-keyframes scale {
    0% {
        opacity: 0;
        box-shadow: 0 0 50px #000000;
        -webkit-transform: scale(2);
        transform: scale(2)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-o-keyframes scale {
    0% {
        opacity: 0;
        box-shadow: 0 0 50px #000000;
        -o-transform: scale(2);
        transform: scale(2)
    }

    100% {
        opacity: 1;
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes scale {
    0% {
        opacity: 0;
        box-shadow: 0 0 50px #000000;
        -webkit-transform: scale(2);
        -o-transform: scale(2);
        transform: scale(2)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

.main-image-box {
    // width: 150px;
    // height: 150px;
    // position: absolute;
    // right: 8%;
    // top: -300px;
    // z-index: 10;
    // border-radius: 5px;
    // transition: all 1s;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    // overflow: hidden;
    display: none;
}

.main-image-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.detail-box {
    width: 100%;
    padding: 20px;
    position: absolute;
    left: 0;
    bottom: -500px;
    z-index: 10;
    transition: all 1s;
    text-align: center;
}

.detail-title {
    width: 100%;
    float: left;
    margin: 5px 0 5px 0;
    padding: 5px 0 5px 0;
    color: #414141;
    font-size: 34px;
    font-weight: bold;
    word-wrap: break-word;
    text-align: justify;
    letter-spacing: 1px;
}

.detail-desc {
    width: 100%;
    float: left;
    margin-top: 0px;
    color: #fff;
    font-size: 45px;
    word-wrap: break-word;
    font-weight: 800;
    line-height: 60px;
    margin-bottom: 15px;
}

.detail-button {
    min-width: 100px;
    min-height: 30px;
    float: left;
    margin-top: 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: none;
    color: #fff;
    cursor: pointer;
}
}