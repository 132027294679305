@charset 'UTF-8';
@import url('https://fonts.googleapis.com/css2?family=Bellota:wght@300;400;700&family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://fonts.googleapis.com/css2?family=Merienda+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind+Guntur:wght@300;400;500;600;700&display=swap');
@import 'includes/custom';
@import 'includes/bootstrap';
@import 'custom/animate';
@import 'custom/slidewiz';
@import 'custom/owl.carousel';
@import 'custom/lightbox';
@import 'custom/layerslider';
@import 'custom/ticker';
@import 'custom/intro';
@import 'custom/navbar';
@import 'custom/home';
@import 'custom/about';
@import 'custom/menu';
@import 'custom/contact';
@import 'custom/catering';
@import 'custom/footer';
@import 'custom/home2';
@import 'custom/mobile';
