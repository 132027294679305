.bm-about-sec1{
    background-color: #403F3F;
    padding: 55px 30px;
}
.bm-about-sec2-p1{
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 1px;
}
.bm-about-sec2-p2{
    font-size: 14px;
    font-weight: 500;
    line-height: 34px;
}
.bm-home-sec2-pdiv{
    max-width: 480px;
    margin: 20px auto;
}
.bm-about-sec1-h3{
    font-size: 24px;
    font-weight: 400;
    letter-spacing: .7px;
    color: #C9C0A6;
}
.bm-about-sec1-p1{
    font-size: 54px;
    font-weight: 800;
    color: #EFEBDD;
    text-transform: uppercase;
    line-height: 63px;
    letter-spacing: 1.5px;
    font-family:'Muli';
    margin-bottom:50px;
}
.bw-sec2-fe-about{
    margin-top:50px;
}
.bm-about-sec1-side1img{
    max-width: 100%;
}
.about-head-p1{
    font-size: 16px;
    color: #D9D2B9;
    letter-spacing: .7px;
    line-height: 43px;
    padding-right: 60px;
    margin-top: 70px;
}
.about-middile-hr{
    width: 100%;
    background-color: #707070;
    height: 2px;
}
.about-video{
    max-width: 464px;
    margin-top:40px;
}