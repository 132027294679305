.catering-sec1 {
  padding-top: 97px;
  overflow: hidden;
}
.catering-h5 {
  font-family: 'Hind Guntur' !important;
  font-weight: 600;
  font-size: 48px;
  line-height: 45px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #1e1e1e;
  padding-left: 30px;
}
// #catering-slider .owl-item {
//   width: 500px !important;
// }
#catering-slider {
  .owl-stage-outer {
    margin-left: 30px !important;
  }
}
.catering-form-sec {
  padding-top: 95px;
  padding-bottom: 94px;
}
.order-fromh5 {
  font-family: 'Hind Guntur';
  font-weight: 600;
  font-size: 48px;
  line-height: 45px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1e1e1e;
}
.order-fromp {
  font-family: 'Hind Guntur';
  font-size: 20px;
  line-height: 45px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #363635;
}
.catering-form-div {
  padding-left: 98px;
  padding-right: 88px;
}
#franchise-slider {
  .owl-stage-outer {
    margin-left: 30px !important;
  }
}
.franchise-familyp {
  font-family: 'Hind Guntur';
  font-size: 20px;
  line-height: 45px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #363635;
  max-width: 621px;
  margin: 0 auto;
}
.connect-withh5 {
  font-family: 'Hind Guntur';
  font-weight: 600;
  font-size: 48px;
  line-height: 45px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #1e1e1e;
  margin-top: 65px;
  margin-bottom: 36px;
}
.catering-form-div .form-control {
  border: 2px solid #1e1e1e;
  font-family: 'Hind Guntur';
  font-size: 26px;
  line-height: 26px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #000000;
}
.catering-form-div label {
  font-family: 'Hind Guntur';
  font-size: 24px;
  line-height: 40px;
  text-align: left;
  color: #1e1e1e;
}
.book-now-submit-catering {
  margin-top: 56px;
  border-radius: 84px;
}
.order-from-row {
  width: 100%;
}

@media (max-width: 767px) {
  .catering-h5 {
    font-size: 28px;
    line-height: 30px;
    padding-left: 15px;
  }
  #catering-slider .owl-stage-outer {
    margin-left: 15px !important;
  }
  .order-fromh5 {
    font-size: 28px;
    line-height: 30px;
  }
  .catering-form-sec {
    padding-top: 35px;
  }
  .order-fromp {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }
  .catering-form-div {
    padding-left: 8px;
    padding-right: 8px;
  }
  .order-from-row {
    width: auto;
    padding-left: 0;
  }
  .catering-form-div label {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
  .catering-form-div .form-control {
    font-size: 16px;
    line-height: 16px;
  }
  .franchise-familyp {
    font-size: 12px;
    line-height: 15px;
  }
  .connect-withh5 {
    font-size: 28px;
    line-height: 30px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  #franchise-slider .owl-stage-outer {
    margin-left: 15px !important;
  }
}
