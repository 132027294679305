.bw-mobile-nav{
    display: none;
}
.bw-intro-footer-mbl1{
    display: none;
}
@media only screen and (max-width: 992px) {
    #intro-nav{
        display: none;
    }
    .bw-intro-footer-mbl1{
        display: block;
    }
    .bw-intro-footer-mbl{
        display: none;
    }
    .bw-mobile-nav{
    display: block;
    height: 58px;
    position: fixed;
    z-index: 999;
    background-color: #1e1e1e;
    width: 100%;
    }
    #sideNav{
        display: none;
    }
    .slider-forward-p{
        top: 110px !important;
    }
    .bm-home-sec1{
        // height: 40vh !important;
        padding-top: 100px;
    }
    .bm-home-sec2{
        padding: 20px;
    }
    .bm-home-sec2-right{
        float:none;
        width: initial;
        margin-left:0px;
    }
    .bm-home-sec2-timings{
        width: initial;
        padding:25px 25px;
    }
    .bm-home-sec2-left{
        margin-top:40px;
    }
    .our-biryanis-row{
        height: initial !important;
    }
    .socialize-row{
        height:initial !important;
    }
    .bm-home-sec4-p1{
        font-size:50px;
    }
    .bm-home-sec4-h4{
        margin-bottom:20px;
    }
    .bm-home-sec2-left-p1{
        font-size: 34px;
        line-height: 42px;
        margin-bottom: 16px;
    }
    .bm-home-sec4-img{
        margin-bottom: 15px;
    }
    .bw-fe-row2{
        margin-top: 0;
    }
    .bw-sec2-fe-row{
        text-align: center;
    }
    .bw-sec2-fe-col-p{
        margin-bottom: 12px;
    }
    .bm-home-sec2-left-p3 a{
        margin-left: 0;
    }
    .hvr-icon-drop{
        margin-bottom:29px;
    }
    .about-video{
        max-width: 265px;
    }
    .bm-about-sec1-p1{
        font-size: 35px;
    line-height: 48px;
    }
    .about-head-p1{
        font-size: 16px;
        color: #D9D2B9;
        letter-spacing: .7px;
        line-height: 36px;
        padding-right: 0;
        margin-top: 20px;
    }
    .delivery-part-pic-div{
        margin-bottom:15px;
    }
    .footer-popular-p-gap{
        height:0px;
    }
    .menu-accordion .card-title{
        font-size:14px;
    }
    .menu-accordion .accordion .card-header:after{
        margin-top:14px;
    }
    .menu-accordion .menu-accordion-tbl td{
        font-size:16px;
    }
    .card-body{
        padding:unset;
    }
    .reach-us-div{
        padding-left:20px;
        margin-top:40px;
    }
    .contact-sec-1{
        padding-left:20px;
        padding-right:20px;
    }
    .reach-us-col5{
        padding-right:15px;
    }
    .phone-number-p, .email-id-p, .address-p{
        font-size:16px;
    }
    .get-exclusive-row{
        padding-left:0px;
    }
    .get-exclusive-h3{
        line-height:35px;
    }
    .subscribe-form-div{
        margin-left:0px;
    }
    .subscribe-btn{
        padding:8px 15px;
    }
    .bm-home-sec3{
        // height:initial !important;
        display: none;
    }
    .bm-about-sec1{
        height:initial !important;
    }
    .bm-home-sec4{
        // height:initial !important;
        padding: 20px;
    }
    .scroll-sec bm-home-sec2{
        padding: 30px;
    }
    .bm-about-sec1-h3{
        margin-top: 65px;
    }
    .bm-menu-sec1-p1{
        margin-top:50px;
    }
    .footer-p-head{
        text-align: center;
    }
    ul.footer-ul-ver li{
        text-align: center;
    }
    .insta-col-mobi{
        text-align: center;
    }
    .biryani-img1{
        max-width: 100%;
    }
    .hyderabadi-h3{
        line-height: 50px;
    }
    .biryani-div1{
        margin-top:10px;
    }
    .select-loc-p{
        margin-left:0;
        margin-top: 30px;
    }
    .location-addHead-div1, .location-add-div2{
        margin: auto;
        margin-bottom: 30px;
    }
    .location-addHead-div2{
        margin: auto;
    }
    .now-take-order-p{
        line-height: 30px;
        font-size: 18px;
    }
    .follow-div-on{
        margin-left:5px;
    }
    .coming-soon-sec1{
        height: unset;
        background-position: center;
        background-image: url("../images/banner-bg-mobi.jpg");
    }
    .followUs-col-desk{
        display: none;
    }
    .followUs-container-mobi{
        display: block;
        margin-top: 50px;
    }
    .select-loc-col2{
        display: none;
    }
    .authentic-p{
        line-height: 20px;
    }
    .bm-home-sec5{
        padding:100px 30px 45px 30px;
    }
    .order-online-mobi{
        display: block;
    }
    .select-loc-card-row{
        display:none;
    }
    .select-loccard-row-mobi{
        display:block;
    }
}
@media(width:768px){
    .biryani-div1{
        margin-top:80px;
    }
    .follow-div-on{
        margin-left:30px;
    }
    .hvr-icon-grow .hvr-icon{
        max-width: 100%;
    }
    
}
@media only screen and (max-width: 991px) and (min-width: 771px)  {
    .coming-soon-sec1 {
     height: 100vh;
    }
    .container{
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .follow-div-on, .follow-p-us {
        text-align: center;
    }
}
@media only screen and (max-width: 535px) {
    .food-note{
        font-size: 11px;
    }
}
@media (min-width: 270px) and (max-width: 360px) {
    .food-note{
        font-size: 7px;
    }
}