@import url('https://fonts.googleapis.com/css2?family=Merienda+One&family=Muli:wght@300;400;500;700;800&display=swap');
body, p, div, *{
    font-family: 'Muli', sans-serif;
}
h1, h2, h3, h4, h5, h6{
    font-family: 'Merienda One', cursive;
}
@media (min-width: 992px){
    #sideNav {
        text-align: center;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 17rem;
        height: 100vh;
    }
    body.bw {
        padding-top: 0;
        padding-left: 17rem;
    }
}
.bg-primary {
    background-color: #1E1E1E!important;
}
.bw-logo{
    max-width: 155px;
    margin-top: 60px;
}
.bw-nav-ul{
    list-style: none;
    padding-left: 0;
}
.bw-nav-ul li{
    padding: 15px 0;
}
.bw-nav-ul li.active{
    background-color: #363535;
}
a.bw-nav-ul-a{
    color: #E2E3E3;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 500;
}
a.bw-nav-ul-a:hover{
    text-decoration: none;
}
.header-row{
    padding: 0;
}
.bw-nav-p4{
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    border-top: 1px solid #585857;
    padding-top: 10px;
}

.location-select{
    background-color: #1E1E1E;
    border: none;
    border-radius: 0;
    color: #E2E3E3;
    font-family:'Muli';
    font-size:20px;
    letter-spacing: 1px;
    font-weight: 500;
}
.location-select:hover{
    cursor: pointer;
}
.location-select:focus{
    border: none;
    border-radius: 0;
    outline: none;
}
.bw-nav-ul li.active .location-select{
    background-color: #363535;
}
.location-select-mobile{
    color: #ffffff;
    font-family: 'Merienda One', cursive;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    padding: 10px 0;
    transition: all 0.3s ease;
    background-color: #1e1e1e;
    border: none;
    
}
.loc-img-side{
    margin-top:-8px;
}