.bw-intro-div{
    position: relative;
}
.bw-intro-head{
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #424242d6;
    z-index: 98;
}
.bw-intro-footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 99;
}
// 
.navbar-expand-sm .navbar-nav .nav-link{
    font-family: 'Merienda One', cursive;
    font-size: 16px;
    color: #ffffff;
    margin: 0 20px;
}
.navbar-expand-sm .navbar-nav .nav-link:hover{
    color: #F9673E;
}
// 
.bw-mobile-nav-logo{
    position: fixed;
    max-width: 120px;
    /* margin: 15px; */
    background-color: #1e1e1e;
    padding: 15px;
    border-radius: 15px;
}

// 
/* Sweep To Right */
.navbar-expand-sm .navbar-nav .nav-link.hvr-sweep-to-right {
    background: #ffffff;
    color: #F9673E;
    padding-left: 20px;
    padding-right: 20px; 
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .navbar-expand-sm .navbar-nav .nav-link.hvr-sweep-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #F9673E;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .navbar-expand-sm .navbar-nav .nav-link.hvr-sweep-to-right:hover, .navbar-expand-sm .navbar-nav .nav-link.hvr-sweep-to-right:focus, .navbar-expand-sm .navbar-nav .nav-link.hvr-sweep-to-right:active {
    color: white;
  }
  .navbar-expand-sm .navbar-nav .nav-link.hvr-sweep-to-right:hover:before, .navbar-expand-sm .navbar-nav .nav-link.hvr-sweep-to-right:focus:before, .navbar-expand-sm .navbar-nav .nav-link.hvr-sweep-to-right:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
//   
.bw-intro-footer-p{
    color: #ffffff;
    text-align: center;
    margin-bottom: 0;
    font-size: 18px;
    padding: 10px 0;
    background-color: #424242d6;
}
a.bw-intro-footer-pa{
    color: #ffffff;
    text-align: center;
    margin-bottom: 0;
    font-size: 18px;
    letter-spacing: 1px;
}
a.bw-intro-footer-pa:hover{
    color: #F9673E;
}

.axlr-logo{
    max-width: 80px;
    display: inline-block;
    margin-bottom: 10px;
}
.bw-intro-footer-p-axlr{
    color: #ffffff;
    font-size: 14px;
    text-align: right;
    margin-right: 80px;
}

// mobile meu - start
.bw-mobile-nav{
    /*  BURGER MENU
    ========================================== */
    .menu {
        width: 50px;
    height: 50px;
    // position: absolute;
    position: fixed;
    z-index: 21;
    right: 1%;
    }
    .menu span {
    position: relative;
    margin-top: 9px;
    margin-bottom: 9px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -1.5px;
    }
    .menu span, .menu span::before, .menu span::after {
    display: block;

        width: 26px;
    right: 0;
    height: 3px;
    background-color: #F9673E;
    outline: 1px solid transparent;
    -webkit-transition-property: background-color, -webkit-transform;
    -moz-transition-property: background-color, -moz-transform;
    -o-transition-property: background-color, -o-transform;
    transition-property: background-color, transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    }
    .menu span::before, .menu span::after {
    position: absolute;
    content: "";
    }
    .menu span::before {
    top: -9px;
    width:20px
    }
    .menu span::after {
    top: 9px;width: 33px;

    }
    .menu.clicked span {
    background-color: transparent;
    }
    .menu.clicked span::before {
    -webkit-transform: translateY(9px) rotate(45deg);
    -moz-transform: translateY(9px) rotate(45deg);
    -ms-transform: translateY(9px) rotate(45deg);
    -o-transform: translateY(9px) rotate(45deg);
    transform: translateY(9px) rotate(45deg);    width: 33px;
    }
    .menu.clicked span::after {
    -webkit-transform: translateY(-9px) rotate(-45deg);
    -moz-transform: translateY(-9px) rotate(-45deg);
    -ms-transform: translateY(-9px) rotate(-45deg);
    -o-transform: translateY(-9px) rotate(-45deg);
    transform: translateY(-9px) rotate(-45deg);
    }
    .menu.clicked span:before, .menu.clicked span:after {
    background-color: #ffffff;
    }
    .menu:hover {
    cursor: pointer;
    }
    /*  NAV
    ========================================== */

    #nav {
    background: #1e1e1e;
    position: fixed;
    z-index: 20;
    top: 0;
    right: 0;
    height: 100%;
    max-width: 100%;
    width: 100%;
    padding: 100px 40px 60px 40px;
    overflow-y: auto;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -o-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    #nav.show {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
    }
    #nav.show ul.main li {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    }
    .menu.clicked {
    position: fixed;
    z-index: 99;
    }
    #nav.show ul.main li:nth-child(1) {
    transition-delay: 0.15s;
    }
    #nav.show ul.main li:nth-child(2) {
    transition-delay: 0.3s;
    }
    #nav.show ul.main li:nth-child(3) {
    transition-delay: 0.45s;
    }
    #nav.show ul.main li:nth-child(4) {
    transition-delay: 0.6s;
    }
    #nav.show ul.main li:nth-child(5) {
    transition-delay: 0.75s;
    }
    #nav.show ul.main li:nth-child(6) {
    transition-delay: 0.9s;
    }
    #nav.show ul.main li:nth-child(7) {
    transition-delay: 1.05s;
    }
    #nav.show ul.main li:nth-child(8) {
    transition-delay: 1.2s;
    }
    #nav.show ul.main li:nth-child(9) {
    transition-delay: 1.35s;
    }
    #nav.show .about, #nav.show .social, #nav.show ul.sub {
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
    transition-delay: .85s;
    }

    @media (min-width: 667px) {
    #nav {
    padding: 120px 30px 70px 20px;
    }
    }
    #nav ul.main {
    list-style-type: none;
    }
    #nav ul.main li {
    -webkit-transform: translateX(40px);
    -moz-transform: translateX(40px);
    -ms-transform: translateX(40px);
    -o-transform: translateX(40px);
    transform: translateX(40px);
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    float: none;
        list-style: circle;
    color: #fff
    }
    #nav ul.main li:last-of-type {
    margin-bottom: 0px;
    }
    #nav ul.main li a {
    color: #ffffff;
    font-family: 'Merienda One', cursive;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    display: block;
    padding: 10px 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    }
    #nav ul.main li a span {
    color: #b7ac7f;
    }
    #nav ul.main li a:hover {
    color: #b7ac7f;
    }
    #nav ul.sub {
    list-style-type: none;
    margin-top: 40px;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    }
    #nav ul.sub li {
    margin-bottom: 10px;
    }
    #nav ul.sub li:last-of-type {
    margin-bottom: 0px;
    }
    #nav ul.sub li a {
    color: #ffffff;
    font-family: "Raleway", sans-serif;
    letter-spacing: 1px;
    font-size: 0.9rem;
    text-decoration: none;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    }
    #nav ul.sub li a:hover {
    color: #b7ac7f;
    }
    .menu:hover span{}

    /*  OVERLAY
    ========================================== */
    .overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #603e82;
    opacity: 0;
    visibility: hidden;
    }
    .overlay.show {
    opacity: 0.8;
    visibility: visible;
    }
}
// mobile men - end
a.bw-intro-footer-mbl1a{
    display: inline-block;
    color: #ffffff;
    background-color: #F9673E;
    text-align: center;
    padding: 10px;
    width: 100%;
}

.bw-mobile1-liimg{
    max-width: 20px;
}