.js-ticker {
    overflow: hidden;
  }
  
  .js-ticker-track {
    white-space: nowrap;
  }
  
  .js-ticker-item {
    white-space: normal;
    height: 100%;
    vertical-align: top;
    display: inline-block;
    position: relative;
  }
  
  @supports (display: flex) {
    .js-ticker-track {
      position: relative;
      white-space: normal;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }
    .js-ticker-item {
      height: auto;
      display: block;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
    }
    .js-ticker.active .js-ticker-track {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .js-ticker.active .js-ticker-item {
      -webkit-box-flex: 1;
          -ms-flex: 1 0 auto;
              flex: 1 0 auto;
    }
  }
  