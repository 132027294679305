.bm-home-sec5{
    padding: 100px 30px 0 30px;
    background-color: #F6F6F6;
}
.footer-p-head{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .7px;
    color: #182C3A;
}
ul.footer-ul-ver{
    list-style: none;
    padding-left: 0;
    li{
        font-size: 16px;
        color: #182C3A;
        letter-spacing: .7px;
        margin-bottom: 12px;
    }
}
.footer-form-tag{
    color: #182C3A;
    font-size: 20px;
    letter-spacing: .7px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 30px;
}
.footer-row1{
    border-bottom: 1px solid #182C3A22;
    padding-bottom: 40px;
}

.footer-sub-but{
    background-color: #F9673E;
    border-color: #F9673E;
    border-radius: 0;
}
.input-group .form-control.footer-innput-but{
    border: 0;
}
.input-group .form-control.footer-innput-but:focus{
    border-color: transparent;
    box-shadow: none;
}
.footer-copy-divp{
    color: #88928B;
    font-size: 12px;
    letter-spacing: .7px;
    text-align: center;
    margin-bottom: 0;
    a{
        color: #88928B;
    font-size: 12px;
    letter-spacing: .7px;
    text-align: center;
    margin-bottom: 0;
        font-weight: 800;
        font-size: 14px;
    }
}
.footer-copy-divp a:hover{
    text-decoration: none;
}
.footer-copy-div{
    border-top: 1px solid #182C3A22;
    padding: 18px 0;
}
.footer-popular-p1{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .5px;
    color: #182C3A;
    margin-bottom: 20px;
    margin-top:40px;
}
.footer-popular-p2{
    color: #182C3A;
    font-size: 14px;
    line-height: 36px;
}
.footer-popular-p-gap{
    height: 60px;
}
.popular-dishes-div{
    margin-bottom:50px;
}
.delivery-part-pic-div{
    text-align: center;
}


// Modal

.formsucess_modalsucess{
    svg {
      width: 71px!important;
      display: block;
      margin: 40px auto 0;
    }
    .path {
      stroke-dasharray: 1000;
      stroke-dashoffset: 0;
    }
    .path.circle {
      -webkit-animation: dash 0.9s ease-in-out;
      animation: dash 0.9s ease-in-out;
    }
    .path.line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
      animation: dash 0.9s 0.35s ease-in-out forwards;
    }
    .path.check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
      animation: dash-check 0.9s 0.35s ease-in-out forwards;
    }
    p {
      text-align: center;
      margin: 20px 0 60px;
      font-size: 1.25em;
    }
    p.success {
      color: #73AF55;
    }
    p.error {
      color: #D06079;
    }
    @-webkit-keyframes dash {
      0% {
        stroke-dashoffset: 1000;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
    @keyframes dash {
      0% {
        stroke-dashoffset: 1000;
      }
      100% {
        stroke-dashoffset: 0;
      }
    }
    @-webkit-keyframes dash-check {
      0% {
        stroke-dashoffset: -100;
      }
      100% {
        stroke-dashoffset: 900;
      }
    }
    @keyframes dash-check {
      0% {
        stroke-dashoffset: -100;
      }
      100% {
        stroke-dashoffset: 900;
      }
    }
    }
    